import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "underscore";

import { BASE_URL, authAxios, axiosConfig, setAxiosToken } from "../../services/client";
import { USER_TOKEN } from "../../services/constants/keys";

import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../../services/cache";
import { decodeJwt } from "../../services/utilities";

const coreState = {
  user_id: null,
  first_name: "",
  last_name: "",
  email: "",
  exp: null,
  is_verified: false,
  token: "",
  roles: [],
  subscriptions: [],
  rentals: []
};

let initialState = {
  ...coreState,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logOut(state) {
      removeLocalStorage(USER_TOKEN);
      Object.assign(state, coreState);
    },
    setToken(state, action) {
      const tokenPayload = decodeJwt(action.payload, 1);
      // console.log('setting token', tokenPayload)
      setAxiosToken(action.payload);
      Object.assign(state, tokenPayload);
      // if (getLocalStorage(USER_TOKEN)) {
      setLocalStorage(USER_TOKEN, action.payload)
      // }
      state.is_verified = true;
    },
  },
  extraReducers(builder) {
    registerUserCases(builder);
    loginUserCases(builder);
    verifyUserCases(builder);
    resetPasswordCases(builder);
    redeemUnverifiedUserCases(builder);
    redeemSubCodeUserCases(builder);
    getUserRentalsCases(builder);
    purchaseContentCases(builder);
  },
});

// Action creators are generated for each case reducer function
export const { logOut, setToken } = userSlice.actions;

export const getUser = (state) => state.user;

export default userSlice.reducer;

export const rehydrateUser = createAsyncThunk("users/rehydrateUser", async (rejectWithValue) => {
  try {
    const response = await authAxios.get(BASE_URL + "auth/rehydrate", axiosConfig);
    return response.data;
  } catch (err) {
    removeLocalStorage(USER_TOKEN);
    return rejectWithValue(err.response.data);
  }
});

export const verifyUser = createAsyncThunk("users/verifyUser", async (token, rejectWithValue) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": token,
      }
    }

    const response = await axios.get(BASE_URL + "auth/verify", config);
    setAxiosToken(token);
    return response.data;
  } catch (err) {
    removeLocalStorage(USER_TOKEN);
    return rejectWithValue(err.response.data);
  }
});

const verifyUserCases = (builder) =>
  builder
    .addCase(verifyUser.fulfilled, (state, action) => {
      const tokenPayload = decodeJwt(action.payload, 1);
      Object.assign(state, tokenPayload);
      state.token = action.payload
      state.is_verified = true;
    })
    .addCase(verifyUser.rejected, (state) => {
      Object.assign(state, coreState);
    });

export const registerUser = createAsyncThunk("users/registerUser", async ({first_name, last_name, email, password }, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const payload = {
      first_name: first_name.replace(/^\s+|\s+$/gm, ""),
      last_name: last_name.replace(/^\s+|\s+$/gm, ""),
      email: email.replace(/^\s+|\s+$/gm, ""),
      password: password,
    };
    const response = await axios.post(BASE_URL + "auth/register", payload, config);
    const token = response.data;
    setAxiosToken(token);
    if (token) {
      setLocalStorage(USER_TOKEN, token);
    }
    return token;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const registerUserCases = (builder) =>
  builder
    .addCase(registerUser.pending, (state) => {
    })
    .addCase(registerUser.fulfilled, (state, action) => {
      const tokenPayload = decodeJwt(action.payload, 1);
      Object.assign(state, tokenPayload);
      state.token = action.payload
      state.is_verified = true;
    })
    .addCase(registerUser.rejected, (state) => {
    });

export const loginUser = createAsyncThunk(
  "users/loginUser",
  async ({ userInfo, rememberUser }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(BASE_URL + "auth/login", userInfo, config);
      const token = response.data;
      setAxiosToken(token);
      if (token && rememberUser === true) {
        setLocalStorage(USER_TOKEN, token);
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const loginUserCases = (builder) =>
  builder
    .addCase(loginUser.pending, (state) => {
    })
    .addCase(loginUser.fulfilled, (state, action) => {
      const tokenPayload = decodeJwt(action.payload, 1);
      Object.assign(state, tokenPayload);
      state.token = action.payload
      state.is_verified = true;
    })
    .addCase(loginUser.rejected, (state, action) => {
    });

  
export const forgotPassword = createAsyncThunk(
  "users/forgotPassword",
  async (payload , { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(BASE_URL + "auth/forgot", payload, config);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const forgotPasswordReset = createAsyncThunk(
  "users/forgotPasswordReset",
  async (payload , { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(BASE_URL + "auth/forgot_reset", payload, config);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async (resetData, { rejectWithValue }) => {
    try {
      const response = await authAxios.post("auth/reset", resetData, axiosConfig);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const resetPasswordCases = (builder) =>
  builder
    .addCase(resetPassword.pending, (state) => {
    })
    .addCase(resetPassword.fulfilled, (state, action) => {
      if (getLocalStorage(USER_TOKEN)) {
        setLocalStorage(USER_TOKEN,action.payload)
      }
      const tokenPayload = decodeJwt(action.payload, 1);
      Object.assign(state, tokenPayload);
      state.token = action.payload
      state.is_verified = true;
    })
    .addCase(resetPassword.rejected, (state, action) => {
    });

export const redeemUnverified = createAsyncThunk("users/redeemUnverified", async ({first_name, last_name, email, password, code }, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let url = "auth/login_redeem";

    let payload = {
      email: email.replace(/^\s+|\s+$/gm, ""),
      code: code.replace(/^\s+|\s+$/gm, ""),
      password: password,
    };

    if (first_name && last_name ) {
      url = "auth/register_redeem";
      payload.first_name = first_name.replace(/^\s+|\s+$/gm, "");
      payload.last_name = last_name.replace(/^\s+|\s+$/gm, "");
    }
    
    const response = await axios.post(BASE_URL + url, payload, config);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const redeemUnverifiedUserCases = (builder) =>
  builder
    .addCase(redeemUnverified.pending, (state) => {
    })
    .addCase(redeemUnverified.fulfilled, (state, action) => {
      state.token = action.payload
    })
    .addCase(redeemUnverified.rejected, (state, action) => {
    });

export const redeemSubCode = createAsyncThunk("users/redeemSubCode", async (code, { rejectWithValue }) => {
  try {
    const url = "subscription/redeem/" + code.replace(/^\s+|\s+$/gm, "");

    const response = await authAxios.get(url, axiosConfig);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const redeemSubCodeUserCases = (builder) =>
  builder
    .addCase(redeemSubCode.pending, (state) => {
    })
    .addCase(redeemSubCode.fulfilled, (state, action) => {
      if (getLocalStorage(USER_TOKEN)) {
        setLocalStorage(USER_TOKEN,action.payload);
      }
      state.token = action.payload;
    })
    .addCase(redeemSubCode.rejected, (state, action) => {
    });

export const purchaseContent = createAsyncThunk("users/purchaseContent", async (order, { rejectWithValue }) => {
  try {
    const url = "shop/order_content";

    const response = await authAxios.post(url, order, axiosConfig);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const purchaseContentCases = (builder) =>
  builder
    .addCase(purchaseContent.pending, (state) => {
    })
    .addCase(purchaseContent.fulfilled, (state, action) => {
      if (getLocalStorage(USER_TOKEN)) {
        setLocalStorage(USER_TOKEN,action.payload);
      }
      state.token = action.payload;
    })
    .addCase(purchaseContent.rejected, (state, action) => {
    });

export const getUserRentals = createAsyncThunk("users/getUserRentals", async (rejectWithValue) => {
  try {
    const url = "subscription/rentals";

    const response = await authAxios.get(url, axiosConfig);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const getUserRentalsCases = (builder) =>
  builder
    .addCase(getUserRentals.pending, (state) => {
    })
    .addCase(getUserRentals.fulfilled, (state, action) => {

      if (action.payload) {
        const now = new Date();

        let activeRentals = [];
        action.payload.forEach(rental => {
          const exp = new Date(rental.rental_expiration);
          // if (now > exp) {
          if (now <= exp) {
            activeRentals.push(rental.content_id);
          }
        })
        state.rentals = _.uniq(activeRentals);
      }
      // if (getLocalStorage(USER_TOKEN)) {
      //   setLocalStorage(USER_TOKEN,action.payload);
      // }
      // state.token = action.payload;
    })
    .addCase(getUserRentals.rejected, (state, action) => {
    });